<script setup lang="ts">
const colorMode = useColorMode()

const { fullPath } = useRoute()
const { url } = useImageStorage()

const emit = defineEmits([
  'click:login',
])

const clickLogin = () => {
  useCookie(`${useRuntimeConfig().public.supabase.cookieName}-redirect-path`).value = fullPath
  navigateTo('/login')
  emit('click:login')
}

const horizontalLogo = computed(() => {
  switch (colorMode.value) {
    case 'dark':
    case 'system':
      return url(true, '/assets/wnm_horizontal_logo_dark.svg')
    default:
      return url(true, '/assets/wnm_horizontal_logo_color.svg')
  }
})
</script>

<template>
  <WMCard :ui="{ base: 'w-full', body: { padding: 'px-8 py-4' } }">
    <div class="flex flex-col gap-2.5">
      <ClientOnly>
        <NuxtImg
          class="w-36 cursor-pointer"
          :src="horizontalLogo"
        />
      </ClientOnly>
      <p class="text-sm">
        {{ $t('main.wnm') }}
      </p>
      <AButton
        button-block
        button-size="lg"
        button-color="green"
        button-variant="outline"
        :button-text="$t('button.login')"
        @click="clickLogin"
      />
    </div>
  </WMcard>
</template>
